// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import styles bundle
import 'swiper/css/bundle'

// Image Grid
const swiperGrid = new Swiper('.swiper-image-grid', {
	loop: false,

	navigation: {
		nextEl: '.custom-swiper-button-next',
		prevEl: '.custom-swiper-button-prev',
	},
})

// Image Carousel
const swiperImages = new Swiper('.swiper-images', {
	loop: false,
	slidesPerView: 1.1,
	spaceBetween: 10,

	navigation: {
		nextEl: '.custom-swiper-button-next',
		prevEl: '.custom-swiper-button-prev',
	},
	breakpoints: {
		1024: {
			slidesPerView: 1.3,
			spaceBetween: 40,
		},
	},
})

// Import CSS for Vite. Path is relative to this JS file
import '../pcss/app.pcss'

//
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.start()
//

/* Jarallax */
import { jarallax } from 'jarallax'

jarallax(document.querySelectorAll('.jarallax'), {
	speed: 0.2,
})

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html & https://nystudio107.com/docs/vite/
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
